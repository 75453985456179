.mainContainer{
    padding: 11.319vw 0vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MuiTab-textColorInherit.Mui-selected{
    color: #712220;
    background-color: #DA9A22;
    border-radius: 0.556vw;
    padding: 0px 0.625vw 0px 0.625vw;
    font-weight: 700;
}
.typo-style1{
    font-size: 2.5vw !important;
    font-weight: 700 !important;
    
}
.view-tab {
    color:#fff !important; 
    font-weight: 600 !important;
    font-size: 0.972vw !important;
    min-width: 8.125vw !important;
    min-height: 2.778vw !important;
    
}
.selected-tab-list {
    background-color:  #712220 !important;
    border-radius: 1.111vw 0vw 0vw 1.111vw !important;
}
.selected-tab-map {
    background-color:  #712220 !important;
    border-radius: 0px 1.111vw 1.111vw 0px !important;
}
.MuiTab-textColorInherit{
    opacity: 1 !important;
}
.tabs-container .MuiTabs-flexContainer{
    gap: 0.417vw !important;
}
.tabs-container{
    min-height: 3.333vw !important;
}
.tab-view-container{
    margin-top: 1.458vw;
    margin-bottom: 1.667vw;
}
.location-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: -4.167vw;
    margin-bottom: 2.083vw;
}
.location-text{
    margin-right: 1.111vw !important;
    font-size: 0.972vw !important;
    font-weight: 500 !important;
    
}
.location-on-off-text{
    font-size: 0.972vw !important;
    font-weight: 600 !important;
    
}

.list-view-tab{
    background-color: #DA9A22 !important;
    border-radius: 1.111vw 0px 0px 1.111vw !important;
}
.map-view-tab{
    background-color: #DA9A22 !important;
    border-radius: 0px 1.111vw 1.111vw 0px !important;
}
.mainIconContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2.292vw;
    margin-bottom: 1.944vw;
    width: 100%;
}
.iconContainer{
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
}
.bar-list-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.list-map-Container{
    width: 100%;
    min-height: 14.792vw;
}
.city-search{
    border: 2px solid #000000;
    width: 15.278vw;
    border-radius: 52px;
    display: flex;
}
.location-search{
    border: 2px solid #000000;
    border-radius: 52px;
    display: flex;
    width: 17.431vw;
}
.tab-segment{
    display: flex;
    margin-left: 16px;
    align-items: center;
}
.tab-segment-all{
    padding: 0px 0.625vw 0px 0.625vw !important;
    min-height: 2.222vw !important;
    min-width: fit-content !important;
    font-size: 0.972vw !important;
    text-transform: none !important;
    
    font-weight: 600 !important;
}
.arrowBtn{
    text-transform: none !important;
    padding: 0 !important;
    font-weight: 700 !important;
    color: #000000 !important;
    font-size: 0.972vw !important;
    
}
.MuiList-padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.menuitem-list{
    
    font-size: 0.972vw !important;
    padding-top: 0.833vw !important;
    background-color: #ECECEC !important;
    color: #712220 !important;
    padding-bottom: 0.833vw !important;
}
.tabs-segment-container{
    min-height: 2.222vw !important;
    display: flex !important;
    justify-content: space-between;
}
.city-state-placeholder .MuiInputBase-input::placeholder {
    color: #646464 !important;
    font-size: 1.111vw !important;
    
}

.location-placeholder .MuiInputBase-input::placeholder {
    color: #646464 !important;
    font-size: 1.111vw !important;
    font-family: "Museo Sans", sans-serif !important;
}

.bar-dropdown{
    display: flex;
    align-items: center;
    padding-left: 1.667vw;
}
.add-icon-advance{
    height: 1.389vw;
}
.search-icon-advance{
    height: 1.389vw !important;
}

.MuiSelect-icon {
    color: #DA9A22 !important;
}
.icon-btn{
    padding: 0.556vw !important;
}
.group-img-map{
    width: 25.417vw;
    height: 19.097vw;
}
.group-img-list{
    height: 19.097vw;
    width: 100%;
    display: block;
    object-fit: cover;
}

.group-img-list-1{
    height: 19.097vw;
    width: 25.417vw;
    display: block;
}

.image-container {
    position: relative;
  }

  .image-container-1 {
    position: relative;
  }

  .star-img-position {
    position: absolute;
    top: 1.111vw; 
    left: 1.111vw;
  }

.btn-advanced{
    padding: 0 !important;
    min-width:fit-content !important;
}

.btn-advanced-1{
    padding: 0 !important;
    min-width:fit-content !important;
}


.icon-img{
    height: 4vw;
}
.img-header-footer{
    background-color: #712220;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.556vw 0.833vw 0.556vw 0.833vw;
    color: #fff;
}
.bar-img-business{
    height: 1.389vw !important;
}
.business-name-typo-advance{
    font-size: 1.111vw !important;
    font-weight: 500 !important;
    
}
.business-details-typo-advance{
    font-size: 0.903vw !important;
    font-weight: 500 !important;
    
}
.view-btn{
    background-color: #FFFFFF !important;
    color:#712220 !important;
    border-radius: 8px !important;
    padding: 0.208vw 1.111vw 0.208vw 1.111vw !important;
    font-weight: 600 !important;
    font-size: 0.972vw !important;
    min-width: 4.792vw !important;
    
}
.header-1{
    display: flex;
    align-items: center;
    gap: 4px;
}
div.gm-style-iw-d{
    max-height: fit-content !important;
    overflow: hidden !important;
}
div.gm-style-iw .gm-style-iw-c{
    max-height: fit-content !important;
}
.gm-style .gm-style-iw  {
    padding: 0 !important;
}
.gm-style .gm-style-iw-c{
    background-color: transparent !important;
    box-shadow: none !important;
}
.gm-ui-hover-effect{
    background-color: #712220 !important;
    opacity: 1 !important;
    margin-bottom: 0.694vw !important;
    width: 2.222vw !important;
    height: 2.222vw !important;
}
.gm-ui-hover-effect > span{
    background-color: #fff !important;
    margin: 0.278vw !important;
    width: 1.667vw !important;
    height: 1.667vw !important;
}
.gm-style .gm-style-iw-tc::after{
    background-color: #712220 !important;
}

  .switch {
    width: 3.2vw !important;
    height: 2.3vw !important;
    padding: 0.209vw !important;
  }
  
  .switch .MuiSwitch-switchBase {
    padding: 0;
    margin: 0.139vw;
    transition-duration: 300ms;
  }
  
  .switch .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(0.833vw) !important;
    color: #fff;
  }
  
  .MuiSwitch-sizeSmall .MuiSwitch-switchBase {
    padding: 0.25vw !important;
  }

  .switch .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: #712220;
    opacity: 1;
    border: 0;
  }
  
  .switch .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track.Mui-disabled {
    opacity: 0.5;
  }
  
  .switch .MuiSwitch-switchBase.Mui-focusVisible .MuiSwitch-thumb {
    color: #33cf4d;
    border: 40px solid #fff;
  }
  
  .switch .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track {
    opacity: 0.3;
  }
  
  .switch .MuiSwitch-thumb {
    box-sizing: border-box;
    width: 1.528vw !important;
    height: 1.528vw !important;
  }
  
  .switch .MuiSwitch-track {
    border-radius: 13px; 
    background-color: #39393d;
    opacity: 1;
    transition: background-color 500ms;
  }


  .circles-container {
    position: absolute;
    bottom: 0.833vw; 
    left: 50%;
    transform: translateX(-50%);
  }
  
  .circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: transparent;
    margin: 0 5px;
    border: 2px solid #FFFFFF;
    cursor: pointer;
  }
  
  .circle.active {
    background-color: #FFFFFF;
  }
  
  .center{
    text-align: center !important;
  }

  .spinnerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14.792vw;
  }