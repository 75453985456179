/* @font-face {
  font-family: "Raleway";
  src : url("../assets/fonts/Raleway.ttf") format("truetype");
} */

.bg1 {
  background-image: url("../assets/login_background.svg");
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: auto;
  display: "flex";
  justify-content: "center"
}

.containerStyle {
  text-align: center;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.live-area{
  max-width: 1080;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.live-area-advance{
  max-width: 1080;
  margin: 0 auto;
  padding: 0 8.4%;
}

.imageStyle img {
  height: 23.194vw;
  width: 30.833vw;
  margin-top: 7.222vw;
}

.formStyle {
  background-color: white;
  border-radius: 0.556vw;
  padding: 4.444vw 5.556vw 4.444vw 5.556vw;
  margin-bottom: 7.083vw;
}

.textStyle .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  color: #732120 !important;  
  border-radius: 5px !important;
  border: 1px solid #732120 !important;
}

.textStyle .MuiOutlinedInput-notchedOutline{
  color: #732120 !important;  
  border-radius: 5px !important;
  border: 1px solid #732120 !important;
}

.formElementStyle {
  border-radius: 5px !important;
  border: 1px solid #70211F !important;
  color: #732120 !important;
  height: 2.778vw;
  width: 29.444vw !important;
  font-weight: 300px !important;
  font-size: 1.111vw !important;
  padding-left: 1.111vw;
  padding-right: 1.111vw;
  margin-top: 0.833vw;
}


.formElementStyle ::placeholder {
  color: #732120;

}

.mb-15 {
  margin-bottom: 1.042vw;
}

.mt-15 {
  margin-top: 1.042vw;
}

.mt-14 {
  margin-top: 0.972vw !important;
}

.mt-32 {
  margin-top: 2.222vw ;
}

.mt-24 {
  margin-top: 1.667vw;
}

.h-40 {
  height:2.778vw !important;
}

.h-38 {
  height:38px !important;
}

.mt-29 {
  margin-top: 2.014vw;
}

.forgotPasswordContainer{
  margin-top: 9px;
}

.forgotPasswordStyle {
  font-size: 0.972vw !important;
  font-weight: 600 !important;
  text-align: center;
  padding: 0 !important;
  text-transform: none !important;

  color: #C33836 !important;
}
.fbImageStyle{
  margin-right: 0.556vw;
  height: 1.389vw;
}

.btnStyle {
  width: 21.597vw !important;
  border: none !important;
  background-color: #F2F2F2 !important;
  display: flex !important;
  font-size: 0.833vw !important;
  font-weight: 400 !important;
  text-align: center !important;
  justify-content: center !important;

  align-items: center !important; 
  height: 3.889vw !important;
  border-radius: 4px !important;
  text-transform: none !important;
}

.btnText {
  color: #1F1F1F;
  font-weight: 600;
  font-size: 0.972vw !important;
  font-family: "Museo Sans", sans-serif !important;
}

.btnImageForApple {
  margin-right: 0.625vw;
  height: 1.389vw;

}

.btnImageForGoogle {
  margin-right: 0.556vw;
  height: 1.389vw;
}

.btnContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
}

.btn1Container {
  display: flex;
  justify-content: center;
  column-gap:20px;
}


.submitCreateBtn:disabled {
  color: #b6abab !important;
  background-color: #700806 !important;
  cursor: not-allowed !important;
}

.invalid-error{
  color: #d81d1a;
  font-size: 12px;
  text-align: right;
  margin-right: 6.25vw;
  margin-top: 0.417vw;
}
.invalid-error-password{
  color: #d81d1a;
  font-size: 12px;
  text-align: right;
  margin-right: 6.25vw;
  margin-bottom: 1.389vw;
  margin-top: -0.625vw;
}

.formStyle-resetpassword {
  background-color: white;
  border-radius: 0.556vw;
  padding: 2.222vw 7.361vw 2.222vw 7.361vw;
  margin-bottom: 10.486vw;
  margin-top: 16.111vw;
}
.formStyle-resetpassword-confirm{
  background-color: white;
  border-radius: 0.556vw;
  margin-bottom: 14.931vw;
  margin-top: 20.556vw;
  padding: 2.222vw 10.486vw;
}
.resetpassword-heading {
  font-size: 1.389vw !important;
  font-weight: 600 !important;
  text-align: center;

}
.resetpassword-description {
  font-size: 0.972vw !important;
  font-weight: 400 !important;
  text-align: center;
  margin-top: 0.833vw !important;
  margin-bottom: 0.556vw !important;

}
.reset-btn{

  border-radius: 0.556vw !important;
  background-color: #70211F !important;
  width: 13.889vw !important; 
  height: 2.778vw !important;
  font-weight: 600 !important;
  font-size: 1.111vw !important;
  color: #FFF !important;
  text-transform: none !important;
}
.backtologin-btn{

  border-radius: 0.556vw !important;
  border: 1px solid #712220 !important;
  background-color: transparent !important;
  width: 13.889vw !important; 
  height: 2.778vw !important;
  font-weight: 600 !important;
  font-size: 1.111vw !important;
  color: #712220 !important;
  text-transform: none !important;
}
.reset-btn-wrapper{
  margin-top: 1.111vw;
}
.eye-icon-style{
  width: 1.528vw;
}

.resetErrorStyle {
  font-size: 0.972vw;
  font-weight: 600;
  text-align: center;
  margin-bottom: 9px;

  color: #C33836 !important;
}