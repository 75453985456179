.itemLibraryGridContainer{
    display: flex;
    justify-content: space-between;
    flex-direction:column;
}
.itemLibraryPaper {
    padding: 1.67vw 2.8vw 2.222vw 2.8vw;
}
.itemHeadingSeeAll {
    display: flex;
    font-size: 1vw;
    justify-content:space-between;
}
.itemGridBox{
    margin-top:1.181vw !important;
    height: 17.5vw !important;
    justify-content:space-between;
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.972vw;
}
.itemGridBoxEventsActivity{
    margin-top:1.67vw !important;
    justify-content:space-between;
}
.recent-activity-container{
    margin: 0 4.861vw !important;
    width: 32.361vw;
    row-gap: 1.389vw;
    display: flex;
    flex-direction: column;
}
.libraryImageContainer{
    border: 1px solid #712220 !important;
    box-shadow: none !important;
    width: 6.806vw !important;
    height: 8.11vw !important;
    border-radius: 8px !important;
}
.friendsContainer{
    height: 8.11vw !important;
    box-shadow: none !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.likedBarContainer{
    width: 6.597vw !important;
    height: 8.125vw !important;
    box-shadow: none !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: none !important;
}
.eventActivityContainer{
    border: 1px solid #712220 !important;
    border-radius: 8px !important;
    box-shadow: none !important;
    display: flex;
    padding: 0.833vw 1.112vw !important;
    align-items: center;
}
.friend-img{
    height: 6.25vw !important;
}
.liked-img{
    height: 4.861vw !important;
    width: 4.861vw !important;
    border-radius: 50%;
}
.event-img{
    height: 4.861vw !important;
    width: 4.861vw !important;
    border-radius: 8px;
}
.recent-img{
    height: 4.861vw !important;
}
.friend-name{
    font-size: 0.98vw !important;
    display: flex !important;
    font-weight: 500 !important; 
}
.bar-name{
    font-weight: 700 !important;
    font-size: 1.12vw !important;
}
.bar-city-state{
    font-weight: 400 !important;
    font-size: 1vw !important;
}

.itemsStyleImportantFeature{
    padding: 1.67vw 2.906vw 0px 2.906vw;
    display: flex;
    justify-content:center;
    flex-direction: column;
}
.itemsStyleBusinessImportantFeature{
    padding: 0px 1.6vw  0px 1.6vw ;
}
.icongridStyleCustomisable{
    justify-content: space-between;
    padding-bottom: 2.222vw;
}
.itemStyleTopThree{
    display: flex;
    flex-direction: column;
    height: 13.958vw !important;
    justify-content: center;
    align-items: center;
}
.disabled{
    opacity: 0.5 !important;
}
.itemStyleYourTops{
    display: flex;
    flex-direction: column;
    padding-left: 2.8vw ;
    padding-right: 2.8vw;
    height: 13.958vw !important;
}
.topThreeCheckinsHeading{
    margin-bottom: 1.67vw !important;
}
.yourTopsHeading{
    margin-top: 1.667vw !important;
    display: flex;
    flex-direction: column;
}
.topThreeCheckinsTypo{
    font-size: 1.25vw !important;
    font-weight: 400 !important;
}
.yourTopsTypo{
    margin-left: 0.56vw !important;
    font-weight: 400 !important;
    font-size: 1.25vw !important;
}
.yourTopsTypoUnselected{
    opacity: 0.5 !important; 
}
.tops-icon-style{
    width: 1.389vw;
}
.itemStyleBadges{
    display: flex;
    flex-direction: column;
    padding: 1.67vw 2.222vw 2.222vw 2.222vw;
}
.itemStyleLiked{
    display: flex;
    flex-direction: column;
    padding: 1.67vw 2.8vw 2.222vw 2.8vw;
}
.imgBadges{
    width: 5.833vw;
}

.itemStyleCheck{
    display: flex;
    padding: 1.875vw 2.8vw 1.95vw 2.8vw;
    justify-content: space-between;
}
.totalCheckHeading{
    color:#712220 !important;
    font-weight:700 !important;
    font-size:1.667vw !important;
}
.totalCheckDes{
    color:#712220 !important;
    font-weight:700 !important;
    font-size:3.5vw !important;
}
.icon-img-customisable{
    height: 2.569vw;
    width: 2.9vw;
}
.fitericon-span{
    font-size: 0.496vw;
    color: #C33836;
    font-family: "Museo Sans", sans-serif !important;
}
.event-acitivity-name{
    font-weight: 700 !important;
    font-size: 1.111vw !important;
}
.event-acitivity-type{
    font-weight: 400 !important;
    font-size: 1.111vw !important;
}
.event-acitivity-date{
    text-shadow: 0px 0px 1px rgb(0, 0, 0);
    font-weight: 400 !important;
    font-size: 1vw !important; 
}

input.adressstyle1 {
    border: 1px solid #712220;
    font-size: 1.111vw;
    border-radius: 4px;
    padding: 0.53vw 1.111vw 0.53vw 1.111vw;
  }
  
  input.adressstyle1::placeholder {
    color: #712220;
    font-size: 1.111vw;
    opacity: 1;
  }
  
  input.adressstyle1:focus {
    border: 1px solid #712220; 
    outline: none;
  }

.formInputProfile .MuiInputBase-input{
    border-radius: 4px ;
    border: 1px solid #732120;
    font-size: 1.111vw;
}
.formInputProfile .MuiOutlinedInput-input{
    font-weight: 500 ;
    font-size: 1.111vw;
    padding: 0.53vw 1.111vw 0.53vw 1.111vw;
}

.formInputProfileTops .MuiInputBase-input{
    border-radius: 4px ;
    border: 1px solid #732120;
    font-size: 1.111vw;
}
.formInputProfileTops .MuiOutlinedInput-input{
    font-weight: 500 ;
    font-size: 1.111vw;
    padding: 0.23vw 0.5vw 0.23vw 0.5vw;
}


.container{
    position: relative !important;
    display: inline-block !important ;
}
.container-business{
    position: relative !important;
    display: inline-block !important ;
}
.buttonStyle {
    position: absolute !important;
    bottom: 1px;
    right: 2px;
    border: none;
    background-color: transparent;
    padding: 0 !important;
    justify-content: right !important;
}
.buttonStyleEvent {
    position: absolute !important;
    top: -0.625vh;
    right: -0.625vh;
    border: none;
    background-color: transparent;
    padding: 0 !important;
    justify-content: right !important;
}
.buttonStyle-business {
    position: absolute !important;
    bottom: 1;
    right: 2;
    border: none;
    background-color: transparent;
    padding: 0 !important;
    justify-content: right !important;
}

/* Business styling  */
.edit-all-btn{
    background-color: #C8572C !important;
    color: #FFFFFF !important;
    font-size: 1vw !important;
    text-transform: none !important;
    border-radius: 8px !important;
    
    width: 8.542vw  !important;
}
.businessProfilePaper{
    border-radius: 8px !important;
    background-color:#EEEDED !important;
}
.businessProfileContainer{
    display: flex;
    flex-direction:column;
    padding: 1.75vw 2.57vw 1.25vw 1.75vw;
}
.businessProfileContainer1{
    display: flex;
    flex-direction:column;
    padding: 0.903vw 2.57vw 0.903vw 1.95vw;
}
.business-info-1-2-3-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.business-info-1{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.business-info-1-2-container{
    display: flex;
    flex-direction: row;
}
.business-info-2{
    display: flex;
    flex-direction: column;
    margin-left: 2.1vw;
    width: 18.958vw;
}
.business-info-3 {
    display: flex;
    flex-direction: column;
    margin-left: 0.764vw;
}
.space{
    justify-content: space-between;
    row-gap:0.764vw;
}
.totalViews{
    color:#712220 !important;
    font-weight:700 !important;
    font-family: "Museo Sans", sans-serif !important;
    font-size: 7.54vw !important;
}
.grid-1-edit-btn{
    display: flex !important;
    justify-content: flex-end !important;
    margin-top: -2.1vw !important;
    color: #732120 !important;
    text-transform: none !important;
    font-size: 0.972vw !important;
    font-weight: 600 !important;
    padding: 0 !important;
    
    background-color: transparent !important;
}

.yourfilter-edit-btn{
    display: flex !important;
    justify-content: flex-end !important;
    
    color: #732120 !important;
    text-transform: none !important;
    font-size: 0.972vw !important;
    min-width: fit-content !important;
    font-weight: 600 !important;
    padding: 0 !important;
}

.itemStyleTotalViews{
    display: flex;
    flex-direction: column;
    height: 15.14vw !important;
    justify-content: center;
    align-items: center;
    border-radius: 8px !important;
    background-color:#EEEDED !important;
}

.wrapper-2-Paper{
    height: 21.46vw;
    border-radius: 8px !important;
    background-color:#EEEDED !important;
}
.analytics-chart{
    margin-top: 3.06vw;
    margin-bottom: 1.88vw;
    height: 9.097vw;
    width: 21.319vw;
}
.analytics-img{
    width: 100%;
}
.itemStyleTotalCheckIn{
    padding-left: 1.95vw;
    padding-right: 1.95vw;
}
.itemStyleLikes{
    padding-left: 1.95vw;
    padding-right: 1.95vw;
}
.itemHeadingSeeAllBusiness {
    display: flex;
    justify-content:space-between;
    padding-top: 1.67vw;
    padding-bottom: 0.876vw;
}
.itemGridBoxBusiness{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 0.972vw;
    margin-top: 1.806vw  !important;
    margin-bottom: 2.92vw   !important;
    justify-content:space-between;
}
.itemHeadingTotalBusiness {
    display: flex;
    justify-content:space-between;
    align-items: center;
}
.totalCheckDesBusiness{
    color:#712220 !important;
    font-weight:700 !important;
    font-size:1.95vw !important;
    font-family: "Museo Sans", sans-serif !important;
}
.topThreeCheckinsHeadingBusiness{
    padding-top: 1.6vw !important;
    text-align: center;
}
.eventPaperWrapper{
    height: 21.46vw;
    border-radius: 8px !important;
    padding-left: 1.74vw;
    padding-right: 1.74vw;
    background-color:#EEEDED !important;
}
.businessEventContainer{
    border: 1px solid #712220 !important;
    background-color:#EEEDED !important;
    box-shadow: none !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2.3vw;
    height: 12.5vw;
    padding-left: 1.74vw;
    padding-right: 1.74vw;
    border-radius: 8px !important;
}
.event-day{
    font-size: 1.39vw  !important;
    font-weight: 700 !important;
    padding-top: 10px !important;  
    
}
.event-date{
    font-size: 1.39vw  !important;
    font-weight: 700 !important;
    
}
.event-time{
    font-size: 1.112vw !important;
    font-weight: 700 !important;
    
}
.event-name{
    font-size: 2.1vw !important;
    font-weight: 700 !important;
    line-height: 1 !important;
    text-align: center;
    color:#712220 !important;
}
.menuPaperWrapper{
    height:38.3vw;
    border-radius: 8px !important;
    padding-left: 1.53vw;
    padding-right: 1.53vw;
    background-color:#EEEDED !important;
}
.itemStyleMenuHeading{
    padding-top: 1.67vw !important;
    padding-bottom:1.319vw !important;
    text-align: center !important;
}
.menuImageContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #D9D9D9;
    height:29.278vw;
    border:1px solid #000000;
    border-radius: 8px;
}
.menuImageSubdirectoryContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #D9D9D9;
    height:21.2vw;
    border-radius: 8px;
}
.menuImageContainerImg{
    width: 100%;
    height:29.278vw;
    border-radius: 8px;
}
.menuImageDirectoryContainerImg{
    width: 100%;
    height:21.2vw;
    border-radius: 8px;
}
.popup-dialog-menuupload .MuiPaper-rounded{
    border-radius: 0.556vw !important;
    width: 55.556vw !important;
    max-width: 55.556vw;
}
.popup-dialog-title-menu{
    display: flex;
    align-items: baseline;
    padding: 0 4.028vw 1.806vw 4.028vw !important;
}
.popup-dialog-title-typo1{
    font-weight: 600 !important;
    font-size: 1.389vw !important;
}
.popup-dialog-title-typo2{
    font-weight: 400 !important;
    font-weight: 0.972vw !important;
    margin-left: 0.694vw !important;
    font-style: italic;
}
.popup-dialog-title-typo3{
    font-size: 1.85vw !important;
    font-weight: 700 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
}
.popup-dialog-title-typo5{
    font-size: 1.389vw !important;
}
.btnBrowse{
    color: rgb(113, 34, 32) !important;
    text-decoration: underline !important;
    padding: 0 !important;
    text-transform: none !important;
    min-width: fit-content !important;
    font-size: 1.85vw !important;
    font-weight: 700 !important;
}
.btnSaveMenuUpload {
    background-color: #70211F !important;
    font-size: 0.904vw !important;
    height: 1.55vw !important;
    width: 4.521vw !important;
    border-radius: 8px !important;
    color: #fff !important;
    text-transform: none !important;
    padding: 0px !important;
    font-weight: 400 !important;
  }
  .popup-dialog-action-menusave{
    padding: 0 4.028vw 1.111vw 4.028vw !important;
  }
  .popup-dialog-content-menuupload{
    background-color: #EFECCA26 ;
    border: 1.67px dashed #712220;
    border-radius: 6.66px;
    height: 24.931vw;
    text-align: center;
    margin: 0 4.028vw 1.111vw 4.028vw !important;
  }
.menuContentWrapper{
    display: flex;
}
.businessMenuContainer1{
    border: 1px solid #C8572C !important;
    background-color:#EEEDED !important;
    box-shadow: none !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 6.68vw;
    border-radius: 8px !important;
}
.menu-type-1{
    color: #C8572C !important;
    font-size: 1vw;
    padding-bottom: 0.56vw;
    font-weight: 700;
}
.menu-des-1{
    font-size: 1vw;
    color: #C8572C !important;
    text-align: center;
}
.businessMenuContainer2{
    border: 1px solid #C33836 !important;
    background-color:#EEEDED !important;
    box-shadow: none !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:6.68vw ;
    border-radius: 8px !important;
}
.menu-type-2{
    font-size: 1vw;
    font-weight: 700;
    padding-bottom: 0.56vw;
    color: #C33836;
}
.menu-des-2{
    font-size: 1vw;
    color:#C33836;
    text-align: center;
}
.businessMenuContainer3{
    border: 1px solid #9C0E2E !important;
    background-color:#EEEDED !important;
    box-shadow: none !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 6.68vw;
    border-radius: 8px !important;
}
.menu-type-3{
    font-size: 1vw;
    font-weight: 700;
    padding-bottom: 0.56vw;
    color:#9C0E2E;
}
.menu-des-3{
    font-size: 1vw;
    color:#9C0E2E;
    text-align: center;
}

.categoryWrapper{
    height: 15.7vw;
    border-radius: 8px !important;
    padding-left: 2.431vw !important ;
    padding-right: 1.806vw !important;
    background-color:#EEEDED !important;
}
.type-category-edit-wrapper{
    display: flex;
    justify-content: space-between;
}
.category-2-1-business{
    padding: 0.576vw 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    height: 11vw;
    overflow-y: scroll;
}
.category-2-1-event{
    padding-top: 0.576vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.category-2-1-box-business {
    padding-bottom: 0.833vw;
    box-sizing: border-box; 
    white-space: nowrap; 
  }
.formControl{
    white-space: nowrap;
    color: #712220;
    font-size: 0.84vw !important;
    
    margin-left: 0.286vw !important;
    font-weight: 500 !important;
}
.formControlCategory{
    white-space: nowrap;
    color: #712220;
    font-size: 1.25vw !important;
    
    margin-left: 0.286vw !important;
    font-weight: 500 !important;
}
.form-control-label{
    margin-left: 0px !important;
    margin-right: 0.694vw !important;
}

.reviewsWrapper{
    height: 21.46vw;
    border-radius: 8px !important;
    background-color:#EEEDED !important;
}
.customerImagesWrapper{
    height: 21.46vw;
    border-radius: 8px !important;
    background-color:#EEEDED !important;
}
.itemStyleCustomer{
    padding-left: 1.667vw;
    padding-right: 1.667vw;
}
.itemStyleCustomerHeading{
    display: flex;
    justify-content:space-between;
    padding-top: 1.111vw;
    padding-bottom: 1.111vw;
}
.itemStyleCustomerFiltersHeading{
    display: flex;
    justify-content:space-between;
    padding-top: 1.111vw;
    padding-bottom: 1.292vw;
}
.itemStyleReviewHeading{
    padding-top: 1.6vw !important;
    text-align: center !important;
}
.itemStyleReviewRating{
    padding-top: 5.14vw;
    text-align: center;
}
.rating-img-wrapper{
    display: flex;
    justify-content: center;
    align-items: baseline;
}
.rating-img{
    height: 2.8vw;
}
.total-reviews{
    font-size: 1vw !important;
    font-family: "Museo Sans", sans-serif !important;
}
.itemStyleReviewSeeall{
    text-align: end;
    padding-top: 3.9vw;
    padding-right: 1.67vw;
}
.rating-typo{
    font-size: 2.8vw !important;
    font-weight: 700 !important;
    font-family: "Museo Sans", sans-serif !important;
    color:#712220 !important;
}
.see-all-typo{
    font-size: 0.972vw !important;
    font-weight: 600 !important;
    cursor: pointer;
    color:#712220 !important;
}
.pointer{
    cursor: pointer !important;
}
.icon-container-business{
    height: 3.889vw;
    display: flex;
    text-align: center;
    width: 20%;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}
.opacity-inactive{
    opacity: 0.5;
}
.itemHeadingSubCategoryBusiness{
    padding-top: 1.67vw;
    text-align: center;
}
.type-category-wrapper{
    display: flex;
    justify-content: space-between;
}
.schedule-item-day{
    font-weight: 600 !important;
    
    font-size: 1vw !important;
    color: #712220 !important;
}
.schedule-item-time{
    font-weight: 400 !important;
    
    font-size: 1vw !important;
    color: #712220 !important;
}
.day-time-typo-bar-owner{
    display: flex;
    
    justify-content: space-between;
    margin: 0px 1.389vw 0.638vw 1.389vw !important;
}
.day-time-typo-bar-owner-2{
    display: flex;
    
    justify-content: space-between;
    margin: 0px 1.389vw 0.238vw 1.389vw !important;
}

.invalid-error-patron{
    margin-top: -2px;
    font-size: 0.833vw;
    color: #f94b4b;
    text-align: right;   
}

.invalid-error-barowner{
    margin-top: -8px;
    font-size: 0.833vw;
    color: #f94b4b;
    text-align: right;   
}

.itemGridBoxCustomer{
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.972vw;
    justify-content: space-between;
}
.customerImageContainer{
    background-color: transparent !important;
    box-shadow: none !important;
    width: 6.597vw !important;
    height: 6.667vw !important;
    border-radius: 8px !important;
}
.businessImageContainer{
    background-color: transparent !important;
    box-shadow: none !important;
    width: 6.597vw !important;
    height: 6.667vw !important;
    border-radius: 8px !important;
}
.businessimagePaceholder{
    border: 1px solid #712220 !important;  
}
.permission-switch-container{
    padding-left: 0.7vw;
    padding-right: 0.6vw;
    padding-top: 0.594vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.permission-switch-typo{
    font-size: 0.833vw !important;
    font-weight: 400 !important;
    font-family: "Museo Sans", sans-serif !important;
    color: #732120 !important;
}

.upload-img-btn-business{
    background-color: #712220 !important;
    color: #FFFFFF !important;
    text-transform: none !important;
    font-weight: 700 !important;
    font-size: 0.972vw !important;
    
    min-width: 5.139vw !important;
    height: 1.667vw !important;
    border-radius: 4px !important;
    cursor: pointer !important;
}
.seeall-upload-container{
    padding-left: 1.736vw;
    padding-right: 0.903vw;
    padding-top: 0.903vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

h2.upload-popup-title{
    background-color: #181818;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 1.25vh;
    padding: 1.667vh !important;
    
}
.upload-popup-close{
    width: 3.472vh;
}
.popup-arrow-btn{
    width: 1.458vh;
    height: 3.194vh;
}
.popup-content-wrapper{
    padding: 1.667vh !important;
}
.popup-content-grid-wrapper{
    padding: 1.667vh 0px 0px 0px !important;
}
.image-center-popup{
    width: 58.056vh !important;
    height: 40.694vh !important;
    margin: 0 auto;
}
.image-center-popup-placeholder{
    width: 58.056vh !important;
    height: 40.694vh !important;
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: #E6E6E6;
    font-weight: 600;
    color:#B0B0B0;
    font-size: 0.972vh;
}
.no-style-btn{
    padding: 0 !important;
    z-index: 1;
    min-width: fit-content !important;
}
.image-grid-popup{
    width: 7.708vh;
    height: 7.778vh;
    border-radius: 10.68px;
}
.image-grid-popup-placeholder {
    width: 7.708vh !important;
    height: 7.778vh !important;
    border-radius: 10.68px;
    background-color: transparent !important;
    border: 1px solid #712220 !important;
    box-shadow: none !important;
}
.upload-img-btn-popup{
    width: 7.708vh;
    height: 7.778vh;
}
.uploadbtn-imggrid-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 2.292vh;
    width: 50.347vh;
}
.pop-up-dialog-barowner .MuiPaper-rounded{
    max-width: 61.389vh !important;
    width: 61.389vh !important;
    border-radius: 8px !important;
}
.upload-popup-btns{
    width: 8.542vh !important;
    height: 2.778vh !important;
    background-color: #712220 !important;
    color: #FFFFFF !important;
    text-transform: none !important;
    border-radius: 8px !important;
    font-weight: 600 !important;
    font-size: 0.972vh !important;
    
}

.upload-popup-btns-event{
    height: 2.778vw !important;
    padding: 0 !important;
    padding: 0 1.181vw !important;
    background-color: #712220 !important;
    color: #FFFFFF !important;
    text-transform: none !important;
    border-radius: 8px !important;
    font-weight: 400 !important;
    font-size: 0.972vw !important;
}
.left-btn{
    position: absolute;
    margin-left: 3.333vh;
}
.right-btn{
    position: absolute;
    margin-right: 3.333vh;
}
.popup-dialog-edit-content{
    padding :0.903vh 2.222vh 1.111vh 2.222vh !important;
}
.edit-save-popup{
    padding: 0 0 2.917vh 2.222vh !important;
}
.popup-description-box{
    margin-top: 1.111vh;
}
.btnSaveEditPopup {
    background-color: #70211F !important;
    font-size: 0.972vh !important;
    height: 2.778vh !important;
    width: 8.542vh !important;
    border-radius: 8px !important;
    color: #fff !important;
    text-transform: none !important;
    padding: 0px !important;
    font-weight: 600 !important;
    
  }
  .invalid-desc-error{
    color: #d81d1a;
    font-size: 0.833vh;
    text-align: right;
    margin-right: 1vh;
    margin-top: 0.417vh;
  }
  .invalid-time-error{
    color: #d81d1a;
    font-size: 0.833vw;
    font-weight: 500;
    text-align: right;
  }
  .pop-up-dialog-title-delete{
    width: 22.5vh !important;
    padding: 1.319vh 0 !important ;
    justify-content: center !important;
  }
  .pop-up-dialog-action-delete{
   justify-content: center !important;
   padding: 0 0 2.778vh 0 !important;
  }
  .pop-up-dialog-title-delete-text{
    font-size: 1.111vh !important;
    font-weight: 500 !important;
    text-align: center !important;
    
  }
  .btnDeleteYes {
    background-color: #70211F !important;
    height: 2.778vh !important;
    width: 10.139vh !important;
    border-radius: 8px !important;
    color: #fff !important;
    text-transform: none !important;
    padding: 0px !important;
    font-size: 1.111vh !important;
    font-weight: 600 !important;
    text-align: center !important;
    
  }

  .radioBox2 {
    width: 1.667vw;
    height: 1.667vw;
    display: inline-block;
    border: 1.5px solid #712220;
    border-radius: 50%;
    position: relative;
  }
  
  .radioBox2.checked::after {
    content: '';
    width: 1.25vw;
    height: 1.25vw;
    background-color: #712220;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .edit-popup-textfield .MuiInputBase-input::placeholder {
    font-size: 1.111vh !important;
}
.txtFieldWrapper {
    display: flex;
    align-items: center;
    gap: 0.625vw;
    width: 18.958vw;
    
}
.pop-up-dialog-amenities-catalogue-1 .MuiPaper-rounded{
    border-radius: 8px !important;
    width: 74.403vw;
    max-width: 74.403vw;
}
.category-3-owner{
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 1.667vw;
}
.category-5-btn-owner{
    color: #712220 !important;
    border: 1px solid #712220 !important;
    min-width: 5.208vw !important;
    border-radius: 4px !important;
    text-transform: none !important ;
    font-size: 0.972vw !important;
    font-weight: 500 !important;
    
    min-height: 2.014vw !important;
    padding: 0 !important;
}
.category-5-btn-owner-clicked {
    background-color: #712220 !important ; 
    font-weight: 500 !important;
    
    color: #fff !important;
    min-width: 5.208vw !important;
    text-transform: none !important ;
    border-radius: 4px !important;
    min-height: 2.014vw !important;
    font-size: 0.972vw !important;
    padding: 0 !important;
}

.pop3-up-dialog-business .MuiPaper-rounded{
    border-radius: 0.556vw !important;
}

.upload-img-btn-business:disabled {
    opacity: 0.8 !important;
    background-color: #700806 !important;
    cursor: not-allowed !important;
  }